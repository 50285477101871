@import "variables.scss";

// --------------------------------------------------------------------------
// FIX FLEX VERTICAL PAR MODULE SPECIFIQUE
// --------------------------------------------------------------------------

app-login,
app-catalog-list,
app-customer-main,
app-customer-list,
app-customer-edit,
app-customer-product-main,
app-customer-product-list,
app-customer-product-edit,
app-customer-popup,
app-forgot-password,
app-home,
app-order-main,
app-order-list,
app-order-edit,
app-product-main,
app-product-list,
app-product-edit,
app-product-item-popup,
app-profile,
app-reset-password,
app-user-main,
app-user-list,
app-user-edit,
app-user-right,
app-user-popup,
app-user-group-main,
app-user-group-list,
app-user-group-edit,
app-user-group-employee,
app-user-group-detail,
app-audit-log-list
 {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

app-order-print {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
// --------------------------------------------------------------------------
// DEFAULT
// --------------------------------------------------------------------------

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 400;
  background: $secondaryBackgroundColor;
}

// --------------------------------------------------------------------------
// SCROLLBAR
// --------------------------------------------------------------------------

/* Works on Chrome, Edge, and Safari */
/* pour la list */
.k-grid-content::-webkit-scrollbar,
.k-dialog-content::-webkit-scrollbar,
.ss-scrollable-container::-webkit-scrollbar {
  width: 8px;
  background: $mainBackgroundColor;
}
/* pour l'edit et le catalogue*/
.ss-edit-content::-webkit-scrollbar,
.ss-main-catalog::-webkit-scrollbar {
  width: 8px;
  background: $thirdBackgroundColor;
}
/* pour la list */
.k-grid-content::-webkit-scrollbar-track,
.k-dialog-content::-webkit-scrollbar-track,
.ss-scrollable-container::-webkit-scrollbar-track {
  background: $mainBackgroundColor;
}
/* pour l'edit et le catalogue */
.ss-edit-content::-webkit-scrollbar-track,
.ss-main-catalog::-webkit-scrollbar-track {
  background: $thirdBackgroundColor;
}
/* pour la list */
.k-grid-content::-webkit-scrollbar-thumb,
.k-dialog-content::-webkit-scrollbar-thumb,
.ss-scrollable-container::-webkit-scrollbar-thumb {
  background-color: #aeb9c2;
  border-radius: 20px;
  border: 3px solid $mainBackgroundColor;
}
/* pour l'edit et le catalogue */
.ss-edit-content::-webkit-scrollbar-thumb,
.ss-main-catalog::-webkit-scrollbar-thumb {
  background-color: #aeb9c2;
  border-radius: 20px;
  border: 3px solid $thirdBackgroundColor;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $secondaryButtonBgColor $mainBackgroundColor;
}

// --------------------------------------------------------------------------
// OVERRIDE KENDO
// --------------------------------------------------------------------------

// DRAWER : FIX FLEX VERTICAL

.k-drawer-container {
  height: 100%;
}

.k-drawer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.k-input,
.k-picker {
  border-color: rgba(0, 0, 0, 0.18);
}

.ss-input-readonly {
  background-color: $thirdBackgroundColor!important;
}

// FIX - catalogue et item cards

//FIX - mettre une largeur minimum aux input dans les items card sur les petits écrans
.ss-item-card .k-input{
    min-width: 70px;
}

// CART - box pour les commentaires: rajouter du padding
.ss-cart-note-col-container .k-input-md .k-input-inner{
  padding-top:25px;
  padding-left:17px;
}

.k-master-row.k-selected {background-color: $gridRowHighlightBgColor !important;}

.k-column-title {
  font-weight: 700;
}

// splitbar

.k-splitbar-horizontal:hover,
.k-splitbar-horizontal:focus,
.k-splitbar-horizontal:active {
  background: #c8d0d6;
  transition: all 0.5ms;
}

// picker

.k-picker,
.k-picker:hover {
  background-color: #fff;
  color: $LabelColor;
}

// button

button.k-rounded-md {
  border-radius: 30px;
  padding: 12px 20px 12px 18px;
  border: none;
}

// checkbox

.k-checkbox {
  border-color: $LabelColor;
  background-color: #ffffff;
}

.k-checkbox-md {
  width: 22px;
  height: 22px;
}

// grid

.k-grid-header {
  padding: 0px 8px 0px 0px !important;
}

.k-table-thead{
  background:$thirdBackgroundColor;
}

.k-grid-header .k-grid-filter:hover {
  background: #fff;
}

.k-grid td,
.k-grid th {
  padding: 13px;
}

// dialog
.k-dialog-actions{
  padding-block: 16px;
  padding-inline: 16px;
}

//select

.k-chip-solid-base,
.k-chip-solid-base:hover{
  color: $secondaryButtonBgColor;
  background-color: $mainBackgroundColor;
}
// radio

.k-radio {
  width: 20px;
  height: 20px;
  border-color: $radioButtonBorderColor;
}

// picker
/*
.k-picker-md .k-input-inner {
  padding: 11px 0px 11px 16px;
}

.k-input-md .k-input-inner {
  padding: 2px 2px 2px 8px;
}
*/
// dialog

.k-dialog-wrapper .k-dialog {
  border-radius: 8px;
}

.k-dialog-titlebar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/*enlever le padding autour des popups + mettre un couleur de BG */
.k-window-content,
.k-prompt-container {
  padding: 0;
  background: $mainBackgroundColor;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

/* enlever la border-top du contenant des boutons dans les popups */
.k-dialog-buttongroup {
  border: none;
  padding: 20px;
}

// Enelever le padding du bas et haut du body de l'item card
.k-card-body{
  padding-bottom:0;
  
}

.ss-main-catalog-row-without-group .k-card-actions{
  padding-top:10px;
}
// Fix couleur texte des dropdown

kendo-dropdownlist {
  .k-input-value-text,
  .k-button-icon {
    color: $LabelColor;
  }
}
//background-color: rgba(255, 255, 255, 0.6);
// GRID : FIX BACKGROUND COLOR DU SELECT PAGE EN MODE DROPDOWN

.k-dropdownlist.k-pager-nav {
  background-color: transparent;
}

/*
.k-dropdownlist.k-pager-nav option {
  background-color: #0f172a;
}
*/

.k-form-fieldset {
  border-width: 0;
  padding: 0;
}

.k-state-selected {
  background-color: $thirdBackgroundColor !important;
}

// Champs avec input et label orientation horizontale

.k-form-horizontal .k-form-field {
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
}

// Highlight row (Group name)

tr.highlight,
tr.k-alt.highlight {
  font-weight: 500;
  color: $darkRed;
}

// notification

.k-notification-group {
  z-index: 999;
}

// --------------------------------------------------------------------------
// CUSTOM GLOBAL CLASSES
// --------------------------------------------------------------------------

// HIDE

.ss-hide {
  display: none;
}

// ROW

.ss-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  * {
    margin-right: 5px;
  }
}

// POPUP

.ss-popup-container, app-user-group-popup {
  flex: 1;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
}

.ss-popup-content-margin {
  margin: 20px;
}

// popup alert error

.ss-pop-alert-error-container {
  width: 100%;
  text-align: left;
}

// enlever du padding de superflu dans les kekndo popup avec des grid
.k-content.k-window-content.k-dialog-content {
  padding: 0px;
}

// enelver la margin top du du popup d'ajout de produit dans Order
.ss-order-product-grid-container kendo-grid {
  margin-top: 0px !important;
}

// MAIN SCOLLABLE

.ss-scrollable-container {
  overflow: auto;
  flex: 1;
  overflow: auto;
}

// MAIN FLEX (composant xxxx-main ou autre qui veut prendre 100% de hauteur)

.ss-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

// MAIN HEADER

.ss-main-header {
  background-color: $thirdBackgroundColor;
  text-align: center;
  padding: 20px 0 6px 0;
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  color: $mainSectionTitle;
}

.ss-pagetitle-separator {
  width: 30px;
  height: 2px;
  background: $mainButtonBgColor;
  box-shadow: none;
  border: none;
}

// LAYOUT FLEX FIX SPLITTER FLEX

.ss-main-splitter {
  flex: 1;
  display: flex;
  overflow: hidden;
  background: $secondaryBackgroundColor;
  padding: 0;
}

.ss-main-splitter-pane-list.k-pane {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ss-main-splitter-pane-edit.k-pane {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-basis: 36%;
}

// LIST / EDIT CONTAINER

.ss-edit-container,
.ss-list-container {
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.ss-list-container {
  padding: 15px 30px 0 30px;
}

.ss-list-container-disabled {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  div {
    background-color: #fff;
    border-radius: 15px;
    line-height: 30px;
    padding: 0 10px;
  }
}

// LIST / EDIT TITLE

.ss-edit-title,
.ss-list-title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  color: $mainSectionTitle;
}

.ss-list-title button {
  margin-left: 10px;
}
// LIST : KENDO GRID

.ss-list-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.k-content.k-window-content.k-dialog-content {
  overflow: auto;
}

// LIST GRID TITLE

.ss-list-grid-title {
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0;
}

// GRID FILTER MULTISELECT

.ss-grid-filter-multiselect {

  .k-chip {
    border: solid 1px $primaryColor;
  }

  .k-chip-label {
    color: $primaryColor;
  }

  .k-chip-actions {
    color: $primaryColor;
  }

}

// EDIT LAYOUT

.ss-edit-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ss-edit-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding: 7px 30px 5px 30px;
  background: $mainBackgroundColor;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
  margin-bottom: 5px;
}

.ss-edit-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 30px 0 30px;
}

.ss-edit-content .ss-bouton-nbrproduits {
  width: 150px;
  margin: 15px 0;
}

.ss-edit-content .k-upload .k-dropzone {
  border-color: rgba(0, 0, 0, 0.08);
  color: #00305e;
  background-color: $mainBackgroundColor;
  padding: 12px;
}

.ss-edit-content .k-upload .k-upload-button {
  border-radius: 30px;
  padding: 8px 18px 8px 16px;
}

.ss-edit-content:first-child {
  margin-top: 20px;
}

.ss-edit-footer {
  padding: 10px;
  background: $mainBackgroundColor;
  box-shadow: -1px 0 5px rgb(0 0 0 / 10%);
  border-top: solid 1px $thirdBackgroundColor;

  button {
    margin-right: 5px;
  }
}

.ss-edit-not-active {
  margin-top: 10px;
  color: #d51923;
}

// CONTENT BUTTON CONTAINER

.ss-edit-content-button {
  padding-top: 10px;

  button {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.ss-edit-group-container {
  border: solid 1px rgba(0, 0, 0, 0.18);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 10px;
}

.ss-edit-group-title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ss-edit-required-field {
  color: red !important;
}

// COLUMN CONTAINER

.ss-form-column-container {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1745px) {
  .ss-form-column-container {
    display: block;
  }
  }

.ss-form-column {
  flex: 1;
  padding: 0 10px;
}

// BUTTON CONTAINER (EDIT FORM)

.ss-button-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ss-button-container > * {
  margin-right: 5px;
}

// FORM FIELD

.ss-formfield {
  margin: 10px 0 25px 0;
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 5px;
    font-size: 15px;
    color: $LabelColor;
  }

  label {
    font-weight: 600;
  }

  .k-input,
  .k-picker {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .k-input {
    padding: 12px 12px 12px 14px;
  }

  .k-checkbox-label {
    margin-right: 10px;
  }

  .k-picker-md .k-input-inner {
    padding: 11px 0px 11px 16px;
  }

  .k-input-md .k-input-inner {
    padding: 2px 2px 2px 8px;
  }
}

.ss-label-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// FORM FIELD : BUTTON

.ss-formfield-button {
  margin: 20px 0 10px 0;

  button {
    margin-right: 5px;
  }
}

// FORM FIELD : ROW

.ss-formfield-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 5px;
  padding-bottom: 5px;

  kendo-label {
    text-align: end;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  > label {
    font-size: 15px;
    color: $LabelColor;
  }

  label {
    font-weight: 600;
  }

  .k-input,
  .k-picker {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.ss-formfield-row kendo-label {
  margin-right: 10px;
}

// NOTIFICATION

.ss-notification {
  padding: 10px;
}

// DIALOG

.ss-dialog-content {
  padding: 20px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ss-dialog-content-preformatted-line-breaks {
  white-space: pre-line !important;
}

// ---------------------------------------------------------------------------------
// AUTH LAYOUT
// ---------------------------------------------------------------------------------

.ss-auth-container {
  /*background-color: #ddd;*/
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  margin: 3% auto auto auto;
}

.ss-auth-panel-welcome {
  background-image: url("/assets/img/login-welcome-bg2.jpg");
  background-size: cover;
  flex: 1;
  margin: 10px 0 10px 10px;
  border-radius: 10px 0 0 10px;
}

.ss-auth-panel-form {
  background-color: #fff;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 10px 10px 0;
  border-radius: 0 10px 10px 0;
}

/* WELCOME */

.ss-auth-welcome-shade {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px 0 0 10px;
}

.ss-auth-welcome-container {
  width: 430px;
  margin: auto;
  padding-left:20px;
  padding-right:20px;
}
.ss-auth-welcome-logo {
  width: 100px;
  margin-bottom: 30px;
}

.ss-auth-welcome-title {
  font-size: 38px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 30px;
}

.ss-auth-welcome-description {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.3em;
}

/* FORM */

.ss-auth-form-title {
  width: 300px;
  font-weight: 700;
  margin: 0 auto 10px auto;
  color: #1e293b;
  font-size: 30px;
}

.ss-auth-fields-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.ss-auth-fields-container input {
  height: 45px;
  font-size: 0.95rem;
}

.ss-auth-error-container {
  width: 300px;
  color: #b70f36;
  margin-bottom: 20px;
  margin: 0 auto;
}

.ss-auth-buttons-container {
  width: 300px;
  margin: 0 auto;
}

.ss-auth-buttons-container.forgot-password .mat-flat-button {
  font-size: 13px;
  font-weight: 400;
  margin-right: 0;
  background:transparent;
}

.ss-auth-buttons-container > * {
  margin-right: 10px;
  margin-bottom: 10px;
}

.ss-auth-form-img {
  width: 300px;
  margin: 0 auto;
}

.ss-auth-form-img img {
  width: 180px;
  margin: 30px 0 50px 0;
}

.ss-auth-buttons-container {
  .ss-auth-quicklogin-title {
    text-align: center;
    text-transform: uppercase;
    color: $mainSectionTitle;
    font-size: 14px;
    font-weight: 600;
  }

  .ss-auth-btn-submit {
    width: 100%;
    background-color: $mainButtonBgColor !important;
    color: $mainButtonTextColor;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 400;
    padding: 6px;
    margin-top: 15px;
  }
  .ss-auth-btn-submit:hover {
    background-color: $secondaryButtonBgColor !important;
    color: $mainButtonTextColor;
  }
}

//Material badge fix pour le panier

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-2px;
  top:-2px;
  background:$secondaryButtonBgColor;
}

// BG sur un popup d'ajout d'adresse - material

.cdk-overlay-pane{
  background:#fff;
}

// FIX flèche retour panier - material
.ss-checkout-back-button .mat-icon {
  height: 37px;
  width: 37px;
}

// enlever le border-radius sur la flèche de retour au panier - material
button.k-rounded-md.ss-checkout-back-button {
  border-radius: 0;
}

/* effet hover plus smooth */

:hover{
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

/* RESPONSIVE FOR BIG SCREENS */

@media screen and (min-width: 1650px) {
  .ss-auth-panel-form {
    width: 500px;
  }
}

/* RESPONSIVE FOR SMALLER SCREENS */


@media screen and (max-width: 1299px) {
  .ss-auth-container {
    width: 86%;
  }
}
